import React from 'react'

function PageNotFount() {
  return (
    <div className='pageNotFound'>
        <h2>Opps Page Not Found Please Enter Correct URL</h2>
    </div>
  )
}

export default PageNotFount